<template>
  <v-card min-width="200" elevation="0" class="player">
    <v-row>
      <v-col md="2" id="play">
        <v-icon v-show="!playing" small v-on:click.prevent="playing = !playing">
          fas fa-play
        </v-icon>
        <v-icon v-show="playing" small v-on:click.prevent="playing = !playing">
          fas fa-pause
        </v-icon>
      </v-col>
      <v-col md="10" class="pt-5" id="seek">
        <div v-on:click="seek" title="Seek">
          <v-progress-linear
            color="light-blue darken-4"
            height="10"
            striped
            rounded
            :value="percentComplete"
          ></v-progress-linear>
        </div>
        <div class="player-time">
          <div class="player-time-current">
            {{ this.currentSeconds | convertTimeHHMMSS }}
          </div>
          <v-spacer />
          <div class="player-time-total">
            {{ this.durationSeconds | convertTimeHHMMSS }}
          </div>
        </div>
      </v-col>
    </v-row>
    <audio
      :loop="looping"
      ref="audio"
      :src="file"
      v-on:timeupdate="update"
      v-on:ondurationchange="load"
      v-on:pause="playing = false"
      v-on:play="playing = true"
      preload="auto"
      style="display: none"
    ></audio>
  </v-card>
</template>

<script>
export default {
  props: {
    file: {
      type: String,
      default: null,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentSeconds: 0,
    durationSeconds: 0,
    loaded: false,
    looping: false,
    playing: false,
    previousVolume: 35,
    showVolume: false,
    volume: 100,
  }),
  computed: {
    muted() {
      return this.volume / 100 === 0;
    },
    percentComplete() {
      return parseInt((this.currentSeconds / this.durationSeconds) * 100);
    },
    fileSrc() {
      return 'teste';
    }
  },
  filters: {
    convertTimeHHMMSS(val) {
      let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

      return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
    },
  },
  watch: {
    playing(value) {
      if (value) {
        return this.$refs.audio.play();
      }
      this.$refs.audio.pause();
    },
    volume(value) {
      console.log(value);
      this.$refs.audio.volume = this.volume / 100;
    },
  },
  methods: {
    download() {
      this.stop();
      window.open(this.file, "download");
    },
    load() {
      this.loaded = true;
      this.durationSeconds = parseInt(this.$refs.audio.duration);

      return (this.playing = this.autoPlay);
    },
    mute() {
      if (this.muted) {
        return (this.volume = this.previousVolume);
      }

      this.previousVolume = this.volume;
      this.volume = 0;
    },
    seek(e) {
      if (!this.loaded) return;

      const el = e.target.getBoundingClientRect();
      const seekPos = (e.clientX - el.left) / el.width;

      this.$refs.audio.currentTime = parseInt(
        this.$refs.audio.duration * seekPos
      );
    },
    stop() {
      this.playing = false;
      this.$refs.audio.currentTime = 0;
    },
    update(e) {
      console.log(e);
      if (
        !isNaN(this.$refs.audio.duration) &&
        isFinite(this.$refs.audio.duration)
      ) {
        this.durationSeconds = parseInt(this.$refs.audio.duration);
        console.log("duration " + this.$refs.audio.duration);
      }

      this.currentSeconds = parseInt(this.$refs.audio.currentTime);
    },
  },
  created() {
    this.looping = this.loop;
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

body {
  font-family: "Nunito", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

$player-bg: #fff;
$player-border-color: darken($player-bg, 12%);
$player-link-color: darken($player-bg, 75%);
$player-progress-color: $player-border-color;
$player-seeker-color: $player-link-color;
$player-text-color: $player-link-color;

.player-wrapper {
  align-items: center;
  background: transparent;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.player {
  background-color: transparent !important;
  color: $player-text-color;
  display: inline-block;
  line-height: 1.5625;
}

.player-controls {
  display: inline;

  > div {
    &:last-child {
      border-right: none;
    }

    a {
      color: $player-link-color;
      display: block;
      line-height: 0;
      padding: 1em;
      text-decoration: none;

      svg {
        display: inline-block;
        width: 1.125rem;
      }
    }
  }
}

.player-progress {
  cursor: pointer;
  height: 10;
  min-width: 200px;
  position: relative;

  .player-seeker {
    background-color: transparent;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
  }
}

.player-time {
  display: flex;
  justify-content: space-between;

  .player-time-current {
    font-size: 8pt;
    padding-left: 5px;
    margin-right: 3px;
  }

  .player-time-total {
    font-size: 8pt;
    padding-left: 5px;
  }
}

.player-volume {
  display: inline-block;
  height: 1.1rem;
  margin: 0 0 0 2px;
  width: 6rem;
}
</style>