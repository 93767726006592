<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="dialog = true" small icon fab>
          <v-icon color="blue">
            fas fa-folder-open
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ nameOfChat(chat) }}
        </v-card-title>

        <v-card-text>
          <v-list dense v-show="chat.type == 'grupo'">
            <v-subheader>Usuarios</v-subheader>
            <v-list-item v-for="user in chat.users" :key="user.id">
              <v-chip>
                {{ user.name }}
                <v-icon
                  v-show="me.id != user.id"
                  color="red"
                  class="pl-4"
                  small
                  @click="remove(user.id)"
                >
                  fas fa-trash
                </v-icon>
              </v-chip>
            </v-list-item>
          </v-list>
          <v-list>
            <v-subheader> Arquivos </v-subheader>
            <v-list-item
              v-for="message in messages"
              :key="
                message.midia != ''
                  ? message.midia + message.nameMidia
                  : message.corpo + message.created_at
              "
              v-show="message.midia != ''"
            >
              <a
                :href="message.midia"
                style="text-decoration: none"
                target="_blank"
              >
                <v-chip>
                  <span v-if="message.nameMidia">
                    {{ message.nameMidia }}
                  </span>
                  <v-icon class="pl-4"> fas fa-cloud-download-alt </v-icon>
                </v-chip>
                <br />
                <span class="horario">
                  {{ message.created_at | formatDate }}
                </span>
              </a>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false" text> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import ToastService from "../../services/ToastService";
export default {
  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },

  watch: {
    chat: function() {
      this.selectChatMessagesFiles();
    },
  },
  data() {
    return {
      dialog: false,
      messages: [],
    };
  },


  filters: {
    formatDate: function(el) {
      if (!el.seconds == null) return;

      return moment.unix(el.seconds).format("HH:mm DD/MM/YYYY");
    },
  },


  mounted() {
    this.selectChatMessagesFiles();
  },

  methods: {
    selectChatMessagesFiles() {
      window.db
        .collection("chat")
        .where("chat_id", "==", this.chat.id)
        .where("tipo", "==", "arquivo")
        .get()
        .then((query) => {
          let allMessages = [];
          query.forEach((obj) => {
            if (obj.data().tipo_midia != "audio/ogg") {
              allMessages.push({ id: obj.id, ...obj.data() });
            }
          });

          this.messages = allMessages;
        })
        .catch((err) => {
          console.log(err);
          ToastService("Erro ao buscar o chat", "error");
        });
    },
    convertDate(created_at) {
      var date = new Date(created_at);

      return date.toLocaleString("pt-BR", {
        hour: "numeric", // numeric, 2-digit
        minute: "numeric", // numeric, 2-digit
        day: "2-digit", // numeric, 2-digit
        year: "2-digit", // numeric, 2-digit
        month: "2-digit", // numeric, 2-digit, long, short, narrow
      });
    },

    nameOfChat(item) {
      if (item.type == "privado") {
        return item.users.find((el) => el.id != this.me.id).name;
      }

      return item.name;
    },
    remove(id) {
      this.$emit("remove", { user_id: id });
    },
  },
};
</script>
